import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getFileUrlV2, getOtherFileDetails } from "../../features/files/fileSlice"; // Redux action
import { useDispatch, useSelector } from "react-redux";
import { getFileDetails } from "../../services/fileService";
import { getVideoSettings } from "../../features/videoSetting/videosettingSlice";
import NotFound from "../NotFound/NotFound";
import { toast } from "react-toastify";
import axios from "axios";

const loadPlyrAssets = () => {
    const plyrCSS = document.createElement('link');
    plyrCSS.rel = 'stylesheet';
    plyrCSS.href = 'https://cdn.plyr.io/3.6.12/plyr.css';
    document.head.appendChild(plyrCSS);

    const plyrJS = document.createElement('script');
    plyrJS.src = 'https://cdn.plyr.io/3.6.12/plyr.polyfilled.js';
    plyrJS.async = true;

    return new Promise((resolve) => {
        plyrJS.onload = () => {
            resolve();
        };
        document.body.appendChild(plyrJS);
    });
};

const AudioPlayer = () => {
    const { videoSetting } = useSelector((state) => state.videosetting)
    const [audioUrl, setAudioUrl] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.file);
    const [audioName, setAudioName] = useState();
    const [fileSize, setFileSize] = useState();
    const [fileErrorFound, setFileErrorFound] = useState(false);
    const audioRef = useRef(null);
    const playerRef = useRef(null); // For storing the Plyr instance

    useEffect(() => {
        const fetchFileDetails = async () => {
            try {
                // Dispatch to fetch file details initially
                const fileDetails = await getFileDetails(id);
                const data = {
                    id: id,
                    fileName: fileDetails?.data?.data?.name
                }
                dispatch(getFileUrlV2(data)).then((res) => {
                    const Audio = res?.payload?.data?.url;
                    setAudioUrl(Audio);
                })

            } catch (error) {
                setFileErrorFound(true);
                toast.error(error?.response?.data?.message)
                console.error("Error fetching file details:", error);
            }
        };

        fetchFileDetails();
    }, [id]);

    useEffect(() => {
        dispatch(getVideoSettings());
        dispatch(getOtherFileDetails(id)).then((res) => {
            setAudioName(res?.payload?.data?.fileName);
            setFileSize(res?.payload?.data?.fileSize);
        })
    }, [dispatch]);

    // Load Plyr assets only once when the component mounts
    useEffect(() => {
        loadPlyrAssets().then(() => {
            playerRef.current = new window.Plyr(audioRef.current, {
                controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
                autoplay: false, // Disable autoplay
            });
        });

        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, []);

    // Reset and load new source when audioUrl changes
    useEffect(() => {
        if (playerRef.current && audioUrl) {
            // Stop the previous audio
            playerRef.current.stop();

            // Reset the audio element
            audioRef.current.src = ""; // Clear the source first
            audioRef.current.load();   // Reset the element

            // Set the new source
            audioRef.current.src = audioUrl;
            playerRef.current.source = {
                type: 'audio',
                sources: [
                    {
                        src: audioUrl,
                        type: 'audio/mp3',
                    },
                ],
            };
        }
    }, [audioUrl, getFileUrlV2]);

    // Download functionality
    const [progress, setProgress] = useState(0);

    const handleDownload = () => {
        axios({
            url: `${process.env.REACT_APP_API_URL}/user/download-file/${id}`, // API endpoint,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                const totalLength = progressEvent?.lengthComputable
                    ? progressEvent.total
                    : parseInt(progressEvent.target.getResponseHeader('content-length') || '0', 10);
                if (totalLength !== 0) {
                    setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
                }
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', audioName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            setProgress(0); // Reset progress after download
        }).catch((error) => {
            console.error('Download failed:', error);
            setProgress(0); // Reset progress on error
        });
    };

    if (fileErrorFound) {
        return (
            <NotFound />
        )
    }
    return (
        <section className="audio-single-sec p-100">
            <div className="container">
                <div className="row">
                    {loading && <p>Loading audio file...</p>}
                    {error && <p>Error loading audio: {error}</p>}
                    {!loading && !error && (
                        <>
                            <div className="col-md-12 text-center">
                                <h2>{audioName}</h2>
                            </div>
                            <div className="col-md-12">
                                <div className="audio-player-container">
                                    <audio ref={audioRef} className="plyr">
                                        {/* The source tag can be removed if Plyr manages it */}
                                    </audio>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                {progress > 0 && (
                                    <div
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: `${progress}%`,
                                                height: '12px',
                                                backgroundColor: '#4caf50',
                                                transition: 'width 0.3s ease-in-out',
                                            }}
                                        />
                                    </div>
                                )}
                                {videoSetting?.videoAccessSettings?.showDownloadButton === true && (
                                    <button className="w-100 download-btn btn btn-primary mb-5" style={{ display: 'inherit' }} onClick={() => { handleDownload() }} disabled={progress > 0 && progress < 100}>
                                        {progress > 0 && progress < 100 ? `Downloading... ${progress}%` : 'Download'}
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AudioPlayer;
