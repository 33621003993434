import React, { useEffect, useState } from "react";
import "./Pricing.css";
import Boxyellow from "../../assets/images/box-yellow.png";
import Bluebox from "../../assets/images/box-blue.png";
import Redbox from "../../assets/images/red-box.png";
import SavefileLogo from "../../assets/images/savefile-logo-pricing.png";
import GoogleDriveLogo from "../../assets/images/google-Drive-logo-pricing.png";
import DropboxLogo from "../../assets/images/dropbox-logo-pricing.png";
import ContactUsFooterTop from "../../Components/ContactUsFooterTop/ContactUsFooterTop";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPlans,
  createCustomer,
  createSubscription,
} from "../../features/plans/planSlice";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { getUserDetail } from "../../features/profile/profileSlice";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Pricing = ({ fromRegisteredUser = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    getPlansStatus,
    monthlyPlansData,
    yearlyPlansData,
    createCustomerStatus,
    customerData,
    createSubscriptionStatus,
    sessionId,
  } = useSelector(state => state.plans);
  const { name, email, userSubscription } = useSelector(state => state.profile);
  const { isAuthenticated, user } = useSelector(state => state.auth);
  const [plans, setPlans] = useState("");
  const [currentPlanId, setCurrentPlanId] = useState(() =>
    isAuthenticated ? userSubscription?.planId : null
  );

  useEffect(() => {
    if (userSubscription) {
      setCurrentPlanId(userSubscription?.planId);
    }
  }, [userSubscription]);

  useEffect(() => {
    const handlePopState = event => {
      if (location.pathname === "/pricing") {
        // Push the same route back onto the history stack
        navigate("/pricing", { replace: true });
      }
    };

    if (isAuthenticated) {
      window.addEventListener("popstate", handlePopState);
    }
    // Listen for the popstate event

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history]);

  useEffect(() => {
    const getPlans = () => {
      dispatch(getAllPlans());
    };
    getPlans();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserDetail());
    }
  }, [isAuthenticated]);

  const handleSubscription = plan => {
    if (!isAuthenticated) {
      toast.error("Please Register before subscription!", {});
      localStorage.setItem("plan", JSON.stringify(plan));
      navigate("/signup");
    } else {
      localStorage.setItem("plan", JSON.stringify(plan));
      if (userSubscription) {
        if (userSubscription.planId == plan.id ? userSubscription?.cancelDate != null ? false : true : false) {
          toast.error("Already Susbcribed to the Plan!", {});
          return;
        }
      }
      const data = {
        name: name,
        email: email,
        plan: plan,
      };
      dispatch(createCustomer(data));
    }
  };

  useEffect(() => {
    if (createCustomerStatus == "succeeded") {
      const data = {
        customerId: customerData["id"],
        priceId: JSON.parse(localStorage.getItem("plan"))["priceId"],
      };
      dispatch(createSubscription(data));
    }
  }, [createCustomerStatus]);

  useEffect(() => {
    const handleRedirect = async () => {
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        toast.error(error, {});
      }
    };
    if (createSubscriptionStatus == "succeeded") {
      handleRedirect();
    }
  }, [createSubscriptionStatus]);

  const handleFreeSubscription = () => {
    if (!isAuthenticated) {
      navigate("/signup");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <>
      <section className="plans-pricing-section p-100 position-relative pb-5">
        <div className="banner-animation-icons">
          <span className="yellow-box">
            <img src={Boxyellow} alt="" />
          </span>
          <span className="box-blue">
            <img src={Bluebox} alt="" />
          </span>
          <span className="red-box">
            <img src={Redbox} alt="" />
          </span>
        </div>

        <div className="top-head-plans">
          <div className="container">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="heading-head mb-5">
                <span className="sub-heading">Know More</span>
                <h2 className="mb-2">Plans and Pricing</h2>
                <p className="mt-4">
                  Choose the best-sized plan for you. Upgrade or cancel at any
                  time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tabs-plan-pricing-section pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tabs-plan-pricing">
                <ul
                  className="nav nav-pills mb-3 justify-content-center"
                  id="pills-tab"
                  role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="false">
                      <span>Monthly</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="true">
                      <span>Yearly</span>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab">
                    <div className="plans-box">
                      <div className="head-top text-center">
                        <p>Save 17% with yearly billing</p>
                      </div>
                      <div
                        className="tabs-pricing-box row"
                        style={{
                          display: fromRegisteredUser ? "flex" : "",
                          justifyContent: fromRegisteredUser ? "center" : "",
                        }}>
                        <div className="col-xl-3 col-lg-6 col-md-6 mb-xl-0 mb-4">
                          <div className="tabs-box h-100">
                            <div className="tabs-pricing h-100 d-flex flex-column">
                              <h5>Free</h5>
                              <h6>$0</h6>
                              <p>USD per month</p>
                              <p className="pb-0 mb-0 big-font-size">
                                <strong>200 GB of Storage</strong> <br />{" "}
                                <strong> Unlimited Bandwidth</strong>
                              </p>
                              <ul>
                                <li>
                                  <strong>Includes</strong>
                                </li>
                                <li>
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M10.0013 18.8146C14.6038 18.8146 18.3346 15.0838 18.3346 10.4813C18.3346 5.87878 14.6038 2.14795 10.0013 2.14795C5.3988 2.14795 1.66797 5.87878 1.66797 10.4813C1.66797 15.0838 5.3988 18.8146 10.0013 18.8146Z"
                                      stroke="#1881FF"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.5 10.4787L9.16667 12.1453L12.5 8.81201"
                                      stroke="#1881FF"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span>Remote Upload</span>
                                </li>
                              </ul>
                              {/* <div
                                className="btn btn-primary"
                                onClick={() => handleFreeSubscription()}>
                                Free
                              </div> */}
                              <button
                                disabled={
                                  userSubscription &&
                                  userSubscription.name == "Free"
                                }
                                style={{
                                  backgroundColor:
                                    userSubscription &&
                                      userSubscription.name == "Free"
                                      ? "#ff3365"
                                      : "",
                                }}
                                className="btn btn-primary"
                                onClick={() => handleFreeSubscription()}>
                                {userSubscription &&
                                  userSubscription.name == "Free"
                                  ? "Susbcribed"
                                  : `Free`}
                              </button>
                            </div>
                          </div>
                        </div>

                        {monthlyPlansData &&
                          monthlyPlansData.length > 0 &&
                          monthlyPlansData.map((plan, key) => (
                            <div
                              className="col-xl-3 col-lg-6 col-md-6 mb-xl-0 mb-4"
                              key={key}>
                              <div className="tabs-box h-100">
                                <div className="tabs-pricing h-100 d-flex flex-column">
                                  {key == 0 && (
                                    <span className="top-head">
                                      Most Popular
                                    </span>
                                  )}
                                  <h5>{plan.name}</h5>
                                  <h6>${plan.price}</h6>
                                  <p>USD per month</p>
                                  <p className="pb-0 mb-0 big-font-size">
                                    <strong>
                                      {plan.storage / 1000} TB of Storage
                                    </strong>{" "}
                                    <br /> <strong> Unlimited Bandwidth</strong>
                                  </p>

                                  <ul>
                                    <li>
                                      <strong>Includes:</strong>
                                    </li>
                                    {/* <li>
                                      <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M10.0013 18.8146C14.6038 18.8146 18.3346 15.0838 18.3346 10.4813C18.3346 5.87878 14.6038 2.14795 10.0013 2.14795C5.3988 2.14795 1.66797 5.87878 1.66797 10.4813C1.66797 15.0838 5.3988 18.8146 10.0013 18.8146Z"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.5 10.4787L9.16667 12.1453L12.5 8.81201"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      <span>Remove Ads</span>
                                    </li> */}
                                    <li>
                                      <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M10.0013 18.8146C14.6038 18.8146 18.3346 15.0838 18.3346 10.4813C18.3346 5.87878 14.6038 2.14795 10.0013 2.14795C5.3988 2.14795 1.66797 5.87878 1.66797 10.4813C1.66797 15.0838 5.3988 18.8146 10.0013 18.8146Z"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.5 10.4787L9.16667 12.1453L12.5 8.81201"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      <span>Remote Upload</span>
                                    </li>
                                  </ul>
                                  <button
                                    disabled={
                                      currentPlanId && currentPlanId == plan.id ? userSubscription?.cancelDate != null ? false : true : false
                                    }
                                    style={{
                                      backgroundColor:
                                        currentPlanId == plan.id
                                          ? userSubscription?.cancelDate != null ? "" : "#ff3365"
                                          : "",
                                    }}
                                    className={
                                      key == 0
                                        ? "btn btn-tertiary"
                                        : "btn btn-primary"
                                    }
                                    onClick={() => handleSubscription(plan)}>
                                    {currentPlanId && currentPlanId == plan.id
                                      ? userSubscription?.cancelDate != null ? `Buy ${plan.name}` : "Susbcribed"
                                      : `Buy ${plan.name}`}
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab">
                    <div className="plans-box">
                      <div className="head-top text-center">
                        <p>Save 17% with yearly billing</p>
                      </div>
                      <div className="tabs-pricing-box row">
                        <div className="col-xl-3 col-lg-6 col-md-6  mb-xl-0 mb-4">
                          <div className="tabs-box h-100">
                            <div className="tabs-pricing h-100 d-flex flex-column">
                              <h5>Free</h5>
                              <h6>$0</h6>
                              <p>USD per Year</p>
                              <p className="pb-0 mb-0 big-font-size">
                                <strong>200 GB of Storage</strong> <br />{" "}
                                <strong> Unlimited Bandwidth</strong>
                              </p>
                              <ul>
                                <li>
                                  <strong>Includes</strong>
                                </li>
                                <li>
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M10.0013 18.8146C14.6038 18.8146 18.3346 15.0838 18.3346 10.4813C18.3346 5.87878 14.6038 2.14795 10.0013 2.14795C5.3988 2.14795 1.66797 5.87878 1.66797 10.4813C1.66797 15.0838 5.3988 18.8146 10.0013 18.8146Z"
                                      stroke="#1881FF"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.5 10.4787L9.16667 12.1453L12.5 8.81201"
                                      stroke="#1881FF"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span>Remote Upload</span>
                                </li>
                              </ul>
                              {/* <div
                                className="btn btn-primary"
                                onClick={() => handleFreeSubscription()}>
                                Free
                              </div> */}

                              <button
                                disabled={
                                  userSubscription &&
                                  userSubscription.name == "Free"
                                }
                                style={{
                                  backgroundColor:
                                    userSubscription &&
                                      userSubscription.name == "Free"
                                      ? "#ff3365"
                                      : "",
                                }}
                                className="btn btn-primary"
                                onClick={() => handleFreeSubscription()}>
                                {userSubscription &&
                                  userSubscription.name == "Free"
                                  ? "Susbcribed"
                                  : `Free`}
                              </button>
                            </div>
                          </div>
                        </div>
                        {yearlyPlansData &&
                          yearlyPlansData.length > 0 &&
                          yearlyPlansData.map((plan, key) => (
                            <div
                              className="col-xl-3 col-lg-6 col-md-6  mb-xl-0 mb-4"
                              key={key}>
                              <div className="tabs-box h-100">
                                <div className="tabs-pricing h-100 d-flex flex-column">
                                  {key == 0 && (
                                    <span className="top-head">
                                      Most Popular
                                    </span>
                                  )}
                                  <h5>{plan.name}</h5>
                                  <h6>${plan.price}</h6>
                                  <p>USD per year</p>
                                  <p className="pb-0 mb-0 big-font-size">
                                    <strong>
                                      {plan.storage / 1000} TB of Storage
                                    </strong>{" "}
                                    <br /> <strong> Unlimited Bandwidth</strong>
                                  </p>
                                  <ul>
                                    <li>
                                      <strong>Includes:</strong>
                                    </li>
                                    {/* <li>
                                      <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M10.0013 18.8146C14.6038 18.8146 18.3346 15.0838 18.3346 10.4813C18.3346 5.87878 14.6038 2.14795 10.0013 2.14795C5.3988 2.14795 1.66797 5.87878 1.66797 10.4813C1.66797 15.0838 5.3988 18.8146 10.0013 18.8146Z"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.5 10.4787L9.16667 12.1453L12.5 8.81201"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      <span>Remove Ads</span>
                                    </li> */}
                                    <li>
                                      <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M10.0013 18.8146C14.6038 18.8146 18.3346 15.0838 18.3346 10.4813C18.3346 5.87878 14.6038 2.14795 10.0013 2.14795C5.3988 2.14795 1.66797 5.87878 1.66797 10.4813C1.66797 15.0838 5.3988 18.8146 10.0013 18.8146Z"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.5 10.4787L9.16667 12.1453L12.5 8.81201"
                                          stroke="#1881FF"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      <span>Remote Upload</span>
                                    </li>
                                  </ul>
                                  <button
                                    disabled={
                                      currentPlanId && currentPlanId == plan.id ? userSubscription?.cancelDate != null ? false : true : false
                                    }
                                    className={
                                      key == 0
                                        ? "btn btn-tertiary"
                                        : "btn btn-primary"
                                    }
                                    style={{
                                      backgroundColor:
                                        currentPlanId == plan.id
                                          ? userSubscription?.cancelDate != null ? "" : "#ff3365"
                                          : "",
                                    }}
                                    onClick={() => handleSubscription(plan)}>
                                    {currentPlanId && currentPlanId == plan.id
                                      ? userSubscription?.cancelDate != null ? `Buy ${plan.name}` : "Susbcribed"
                                      : `Buy ${plan.name}`}
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {!fromRegisteredUser && (
        <>
          <section className="plan-free-box mt-4 mb-4">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="plan-free-box">
                    {/* <p>
                      *Estimated price in your local currency. You will be
                      charged in euros for all transactions.
                    </p> */}
                    <div className="dark-box-plan d-flex flex-wrap align-items-center">
                      <div className="left-box">
                        <h2>Get started with our free plan</h2>
                        <ul>
                          <li>
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.0013 18.8146C14.6038 18.8146 18.3346 15.0838 18.3346 10.4813C18.3346 5.87878 14.6038 2.14795 10.0013 2.14795C5.3988 2.14795 1.66797 5.87878 1.66797 10.4813C1.66797 15.0838 5.3988 18.8146 10.0013 18.8146Z"
                                stroke="#1881FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.5 10.4787L9.16667 12.1453L12.5 8.81201"
                                stroke="#1881FF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span>200 GB free</span>
                          </li>
                        </ul>
                      </div>
                      <div className="right-box">
                        <h5>
                          $0 <sub>Forever</sub>
                        </h5>
                        <div
                          className="btn btn-primary d-block"
                          onClick={() => handleFreeSubscription()}>
                          Get start for Free{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="compare-section mb-5 mt-5">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-12">
                  <h2>See how we compare</h2>
                </div>
                <div className="col-md-4">
                  <div className="compare-box">
                    <div className="inner-compare-box">
                      <img src={SavefileLogo} alt="" />
                      <h6 className="price-box">$6.87*</h6>
                      {/* <p>Per TB per month</p> */}
                      <p className="mb-0">
                        *Price based on Pro III annual plan per TB
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="compare-box">
                    <div className="inner-compare-box">
                      <img src={GoogleDriveLogo} alt="" />
                      <h6 className="price-box">$16.65*</h6>
                      {/* <p>Per TB per month</p> */}
                      <p className="mb-0">
                        *Price based on Basic annual plan per TB
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="compare-box">
                    <div className="inner-compare-box">
                      <img src={DropboxLogo} alt="" />
                      <h6 className="price-box">$9.99*</h6>
                      {/* <p>Per TB per month</p> */}
                      <p className="mb-0">*Price based on Plus annual plan</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="faq-section mb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="inner-faq-box">
                    <h2 className="text-center mb-4">
                      Frequently asked questions
                    </h2>

                    <div className="faq-box">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne">
                              Can I use SaveFiles for free?
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <div className="accordion-box">
                                <p>
                                  Yes, our free plan offers 200 GB of storage
                                  and unlimited bandwidth. You can upgrade to
                                  our Pro plans for additional storage and
                                  enhanced features.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo">
                              Can I earn money with SaveFiles?
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <div className="accordion-box">
                                <p>
                                  Absolutely! Earn up to $35 per 10,000 streams
                                  or download of your files through our Partner
                                  Program. Start sharing your files on your
                                  website, blog, social media, or any other
                                  platform to begin earning today!
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree">
                              How secure and private are my files?
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <div className="accordion-box">
                                <p>
                                  We prioritize your privacy with server-side
                                  encryption to protect your files. We also
                                  adhere to DMCA guidelines and will respond to
                                  valid takedown requests. For details, please
                                  review our Copyright Policy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour">
                              Is there a limit on the size of individual files I
                              can upload?
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <div className="accordion-box">
                                <p>
                                  Yes, users have a maximum file size limit of
                                  15 GB per individual file.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFifth">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFifth"
                              aria-expanded="false"
                              aria-controls="collapseFifth">
                              How much storage would I need?
                            </button>
                          </h2>
                          <div
                            id="collapseFifth"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFifth"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <div className="accordion-box">
                                <p>
                                  Documents typically use minimal storage, but
                                  photos and videos require more space. If you
                                  need to store a large volume of files,
                                  especially videos, our Pro plans offer the
                                  best solution.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingSix">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix">
                              What payment methods can I use?
                            </button>
                          </h2>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <div className="accordion-box">
                                <p>
                                  We accept payments via credit and debit cards.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ContactUsFooterTop />
        </>
      )}
    </>
  );
};

export default Pricing;
