import React, { useEffect, useState, useRef } from "react";
import "./VideoPlayer.css";
// import "./plyr-plugin-thumbnail"
import { useParams } from "react-router-dom";
import { getFileUrl, getVideoFileDetails, getVideoSignedUrl, getVideoSubtitle, getVideoThumbnail, videoViewCount } from "../../features/files/fileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIpAddressService } from "../../features/ipInfo/ipInfo";
import { getVideoSettings } from "../../features/videoSetting/videosettingSlice";
import { useDetectAdBlock } from "adblock-detect-react";
import { Helmet } from "react-helmet";
import { TbSpiral } from "react-icons/tb";
import axios from "axios";
import Hls from 'hls.js';
import Loading from "../../Components/Loading/Loading";
import { toast } from "react-toastify";
import { getUserDetail } from "../../features/profile/profileSlice";


const VideoPlayer = () => {
  const [isCaptionsReady, setIsCaptionsReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const scriptRefs = useRef([]);
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      console.log('ad block detected');
    }
  }, [adBlockDetected]);
  const token = localStorage.getItem("token");
  const [videoUrl, setVideoUrl] = useState();
  const [downloadUrl, setDownloadUrl] = useState('');
  const [videoThumbnail, setVideoThumbnail] = useState();
  const [subtitle, setSubtitle] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { gettedfileData, getFileUrlStatus, getFileDetail, getThumbnail } = useSelector(
    (state) => state.file
  );

  const { videoSetting } = useSelector((state) => state.videosetting)
  const { ipInfoData, ipInfoDataStatus } = useSelector((state) => state.ipInfo);

  const [videoName, setVideName] = useState();
  const [fileSize, setFileSize] = useState();
  const [isFivePercentWatched, setIsFivePercentWatched] = useState(false);
  const [subtitleList, setSubtitleList] = useState([]); //old one

  const [colors, setColors] = useState({
    overlaidBackground: '0,178,255',
    rangeColor: '0,178,255',
    buttonHoverBackground: "transparent",
    controlHoverBackground: '0,178,255',
  });

  const iframeHeight = videoSetting?.videoPlayerSettings?.embedHeight || '600';
  const iframeWidth = videoSetting?.videoPlayerSettings?.embedWidth || '800';

  // useEffect(() => {
  //   // Create a script element
  //   const script = document.createElement("script");

  //   // Define the script content with your Monetag code
  //   script.innerHTML = `
  //     (function(d, z, s) {
  //       s.src = 'https://' + d + '/400/' + z;
  //       try {
  //         (document.body || document.documentElement).appendChild(s);
  //       } catch (e) {}
  //     })('nunsourdaultozy.net', 8196672, document.createElement('script'));
  //   `;

  //   // Append the script to the body or head
  //   document.body.appendChild(script);

  //   // Create the second script element for full-page popup
  //   // const popupScript = document.createElement("script");
  //   // popupScript.src = "//thubanoa.com/1?z=8225630"; // The full-page popup script URL
  //   // popupScript.async = true;
  //   // popupScript.setAttribute("data-cfasync", "false"); // Set any necessary attributes

  //   // // Append the popup script to the body
  //   // document.body.appendChild(popupScript);

  //   const loadAdsterraScript = () => {
  //     const popunderOptions = {
  //       siteId: '4302114', // Replace with your Adsterra site ID
  //       popunder_frequency_delay: 0, // Frequency in seconds (0 for immediate)
  //       window_open_delay: 0,       // Delay before opening window in milliseconds
  //     };

  //     const script = document.createElement('script');
  //     script.src = '//inevitablyflop.com/24/b3/5b/24b35b4bb557a50f76894b8e8bccd7f5.js';
  //     script.type = 'text/javascript';
  //     script.async = true;

  //     document.body.appendChild(script);
  //   };
  //   loadAdsterraScript()
  //   // Cleanup function: Remove the script when the component unmounts
  //   return () => {
  //     // Find the script we added and remove it
  //     if (script) {
  //       document.body.removeChild(script);
  //     }
  //     // if (popupScript) {
  //     //   document.body.removeChild(popupScript);
  //     // }
  //   };
  // }, [dispatch]); // Empty dependency array ensures this runs only once on mount

  // useEffect(() => {
  //   dispatch(getFileUrl(id));
  //   dispatch(getVideoSettings());
  //   dispatch(getVideoFileDetails(id)).then((response) => {
  //     if (response?.payload?.success === true) {
  //       dispatch(getVideoSignedUrl(response?.payload?.data?.name))
  //     }
  //   })
  // }, [id, dispatch]);
  useEffect(() => {
    dispatch(getFileUrl(id));
    dispatch(getVideoSettings());

    // Dispatch `getVideoFileDetails` and log the response
    dispatch(getVideoFileDetails(id)).then((response) => {

      if (response?.payload?.success === true) {
        // Dispatch `getVideoSignedUrl` and log its response
        dispatch(getVideoSignedUrl(response?.payload?.data?.name)).then((signedUrlResponse) => {
        });
      }
    });
  }, [id, dispatch]);


  useEffect(() => {
    if (gettedfileData.url != "") {
      // setVideoUrl(gettedfileData.url);
      setVideName(gettedfileData.fileName);
      setFileSize(gettedfileData.fileSize);
    }
  }, [gettedfileData]);

  useEffect(() => {
    // Extract and set video URL from gettedfileData
    if (gettedfileData?.data?.url) {
      // setVideoUrl(gettedfileData.data.url);
    }
  }, [gettedfileData]);

  useEffect(() => {
    dispatch(getIpAddressService());
  }, [dispatch]);


  const handleTimeUpdate = () => {
    const video = playerRef.current;
    const currentTime = video?.currentTime;
    const duration = video?.duration;

    if (duration > 0 && ipInfoDataStatus != 'failed') {
      const percentageWatched = (currentTime / duration) * 100;
      if (percentageWatched >= 5 && !isFivePercentWatched) {
        setIsFivePercentWatched(true);
        const data = {
          id: id,
          viewType: "VIDEO",
          ip: ipInfoData.ip,
          country: ipInfoData.country,
          code: ipInfoData.country,
          isAdblock: adBlockDetected === true ? 1 : 0
        };
        dispatch(videoViewCount(data));
      }
    }
  };

  const playerRef = useRef(null);
  const [isPlyrLoaded, setIsPlyrLoaded] = useState(false);
  const [subtitleUrl, setSubtitleUrl] = useState([0]);
  const [showTitle, setShowTitle] = useState(true);  // Show title initially on pause
  const [isFileOwner, setIsFileOwner] = useState(true);
  const [adLevel, setAdLevel] = useState(null);
  const [videoSettingResponse, setVideoSettingResponse] = useState(null);

  // Function to make the object dynamic
  const createCaptionsArray = (subtitles) => {
    return subtitles?.map((subtitle, index) => ({
      url: subtitleUrl[index],
      language: subtitle?.lang?.toLowerCase().substring(0, 2), // To get short language code
      label: subtitle?.language?.name,
      // default: index === 0 // Set the first subtitle as default
      // default: true // Set the first subtitle as default
    }));
  };
  const captions = createCaptionsArray(subtitleList);


  useEffect(() => {
    if (getFileDetail?.data?.name && id) {
      getVideoPlayUrl(getFileDetail?.data?.name, id);
    }
    // else {
    //   console.warn("getFileDetail or id is missing.");
    // }
  }, [getFileDetail, id]);


  const getVideoPlayUrl = async (fileName, fileKey) => {
    const url = process.env.REACT_APP_API_URL;
    if (!url) {
      throw new Error("Not able to find the URL");
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/user/getFileUrlV2?filename=${fileName}&key=${fileKey}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setVideoSettingResponse(response?.data?.data);
      setVideoUrl(response?.data?.data?.url)
      setDownloadUrl(response?.data?.data?.downloadUrl)
      setVideoThumbnail(response?.data?.data?.thumbnailUrl)
      setSubtitle(`${response?.data?.data?.subtitles}`)
      setIsFileOwner(response?.data?.data?.fileOwner);
      setAdLevel(response?.data?.data?.settings?.adLevelVideo);
      setColors({
        overlaidBackground: response?.data?.data?.settings?.videoPlayerSettings?.customColor,
        rangeColor: response?.data?.data?.settings?.videoPlayerSettings?.customColor,
        controlHoverBackground: response?.data?.data?.settings?.videoPlayerSettings?.customColor,
      })
    } catch (error) {
      if (error?.response?.data?.success === false) {
        // toast.error(error?.response?.data?.message)
      }
      // console.error("Error fetching video URL:", error);
      // throw error;
    }
  };


  useEffect(() => {
    let thumbnail = getFileDetail?.data?.thumbnail;
    if (thumbnail !== undefined && thumbnail !== '') {
      dispatch(getVideoThumbnail(thumbnail));
    }
    let subtitle = getFileDetail?.data?.subtitles;
    // let subtitle = response.data.data.subtitles
    setSubtitleList(subtitle);
    let subtitleArray = [];
    const fetchSubtitles = async () => {
      if (subtitle !== undefined && subtitle.length > 0) {
        const promises = subtitle.map(item => {
          // return dispatch(getVideoSubtitle(item?.subtitlefile)).then((res) => {
          //   return res?.payload?.data?.url;  // Return the subtitle URL
          // });
          return item.subtitlefile;
        });
        subtitleArray = await Promise.all(promises); // Wait for all promises to resolve
        setSubtitleUrl(subtitleArray);
      }
    };

    fetchSubtitles();
    // Function to dynamically load a script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };
    let hls;

    let captionSize = 32; // Default subtitle size in px

    // Function to adjust subtitle size
    const adjustCaptionSize = (newSize) => {
      const captions = document.querySelectorAll('.plyr__caption');
      captions.forEach((caption) => {
        caption.style.fontSize = `${newSize}px`;
      });
    };
    if (isCaptionsReady) {

      // Load both the Plyr and HLS.js scripts
      Promise.all([
        loadScript('https://cdn.plyr.io/3.7.2/plyr.js'), // Plyr CDN
        loadScript('https://cdn.jsdelivr.net/npm/hls.js@latest') // HLS.js CDN
      ])
        .then(() => {

          if (window.Plyr && window.Hls) {
            const player = new window.Plyr(playerRef.current, {
              title: 'Example Title',
              controls: [
                'play-large', 'progress', 'play', 'mute', 'volume', 'current-time', 'duration',
                'settings', 'pip', 'title', 'fullscreen', 'captions',
              ],
              tooltips: {
                controls: true, // Enable tooltips for controls
              },
              settings: ['captions', 'quality', 'speed', 'loop', 'audio'],
              captions: {
                active: true,
                update: true
              },
              // quality: {
              //   default: 576,
              //   options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
              //   forced: true,
              //   onChange: (e) => {
              //   },
              // },
              // thumbnail: {
              //   enabled: true,
              //   pic_num: 184,
              //   width: 178,
              //   height: 100,
              //   col: 7,
              //   row: 7,
              //   offsetX: 0,
              //   offsetY: 0,
              //   urls: [
              //   ]
              // },
            });
            // Add custom event listener for track change
            player.on('ready', () => {
              const captionsMenu = document.querySelector('.plyr__menu__container [role="menu"]');

              // Check if the resizeSubtitles option is enabled
              if ((videoSetting?.videoPlayerSettings?.resizeSubtitles || videoSettingResponse?.settings?.videoPlayerSettings?.resizeSubtitles) && captionsMenu) {
                const sizeControlsContainer = document.createElement('div');
                sizeControlsContainer.className = 'custom-caption-size-controls';

                const increaseBtn = document.createElement('button');
                increaseBtn.innerText = '+';
                increaseBtn.onclick = () => {
                  captionSize = Math.min(captionSize + 2, 90); // Limit maximum size
                  adjustCaptionSize(captionSize);
                };

                const sizeSpan = document.createElement('span');
                sizeSpan.innerText = 'Size';

                const decreaseBtn = document.createElement('button');
                decreaseBtn.innerText = '-';
                decreaseBtn.onclick = () => {
                  captionSize = Math.max(captionSize - 2, 10); // Limit minimum size
                  adjustCaptionSize(captionSize);
                };

                sizeControlsContainer.appendChild(increaseBtn);
                sizeControlsContainer.appendChild(sizeSpan);
                sizeControlsContainer.appendChild(decreaseBtn);

                captionsMenu.appendChild(sizeControlsContainer);
              }
            });
            const controls = player.elements.controls;
            if (controls) {
              // Create custom button wrapper using vanilla JS
              const customButtonWrapper = document.createElement('div');
              customButtonWrapper.className = 'plyr__control plyr__control--custom';

              // Create the button
              const customButton = document.createElement('button');
              customButton.setAttribute('type', 'button');
              customButton.setAttribute('aria-label', 'Visit SaveFiles');
              customButton.className = 'plyr__control plyr__control--icon';
              customButton.onclick = () => {
                window.open('https://savefiles.com/', '_blank'); // Open the URL in a new tab
              };

              // Use React Icon (TbSpiral) in the button
              const IconComponent = () => <TbSpiral size={24} color="#fff" />;

              // Add tooltip span element to the button
              const tooltipSpan = document.createElement('span');
              tooltipSpan.className = 'plyr__tooltip'; // Tooltip class as required by Plyr
              tooltipSpan.innerText = 'Visit SaveFiles'; // Text for the tooltip

              // Append both the custom button and tooltip to the button wrapper
              customButton.appendChild(tooltipSpan); // Add the tooltip alongside the icon
              customButtonWrapper.appendChild(customButton); // Append the button to the wrapper
              controls.appendChild(customButtonWrapper); // Append the wrapper to Plyr controls

              // Render the React icon component into the button using ReactDOM
              import('react-dom').then(ReactDOM => {
                const iconContainer = document.createElement('div'); // Create a container for the icon
                iconContainer.className = 'custom-control';
                customButton.appendChild(iconContainer); // Append it to the button
                ReactDOM.render(<IconComponent />, iconContainer); // Render the icon inside the button
              });
            }

            var plyr = document.querySelector('.video-container>.plyr');
            var overlay = document.querySelector(".channel-icon")

            if (overlay != null) {
              plyr.append(overlay)
            }

            let chunkName = getFileDetail?.data?.chunkName;
            let videoFileName = getFileDetail?.data?.name;
            let quality = getFileDetail?.data?.quality[0]?.resolution;
            let token = 'token';
            let expires = '1731056476';

            if (typeof getFileDetail?.data?.quality !== 'undefined' && getFileDetail?.data?.quality?.length > 0) {
              const videoSrc = videoUrl;
              const video = playerRef.current;

              if (video && Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(video);

                // hls.on(Hls.Events.MANIFEST_PARSED, () => {
                //     video.play().catch((error) => {
                //         console.error("Error playing video:", error);
                //     });
                // });
                hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, () => {
                  const audioTracks = hls.audioTracks;

                  if (audioTracks.length > 1) {
                    const settingsMenu = document.querySelector('.plyr__menu__container [role="menu"]');
                    const currentAudioTrack = hls.audioTrack;

                    if (settingsMenu) {
                      // Remove existing audio button if any
                      const existingAudioButton = document.querySelector('.plyr__control[data-plyr="audio"]');
                      if (existingAudioButton) {
                        existingAudioButton.remove();
                      }

                      // Create the audio button
                      const audioButton = document.createElement('button');
                      audioButton.setAttribute('data-plyr', 'audio');
                      audioButton.setAttribute('type', 'button');
                      audioButton.setAttribute('class', 'plyr__control plyr__control--forward');
                      audioButton.setAttribute('role', 'menuitem');
                      audioButton.setAttribute('aria-haspopup', 'true');

                      // Add inner content for the button
                      const selectedTrackName = audioTracks[currentAudioTrack]?.name || audioTracks[currentAudioTrack + 1]?.name;
                      audioButton.innerHTML = `
                        <span>
                          Audio Track
                          <span class="plyr__menu__value">${selectedTrackName}</span>
                        </span>
                      `;

                      // Append the button to the settings menu
                      settingsMenu.insertBefore(audioButton, settingsMenu.firstChild);

                      // Handle button click to display the audio track list
                      audioButton.addEventListener('click', () => {
                        const audioMenu = document.querySelector('.plyr__menu__audio');
                        if (audioMenu) {
                          audioMenu.classList.toggle('visible');
                        } else {
                          createAudioMenu(audioTracks, hls, selectedTrackName);
                        }
                      });
                    }
                  }
                });

                // Function to create the audio track menu
                function createAudioMenu(audioTracks, hls, selectedTrackName) {
                  const settingsMenu = document.querySelector('.plyr__menu__container [role="menu"]');
                  const audioMenu = document.createElement('div');
                  audioMenu.className = 'plyr__menu__container plyr__menu__audio visible';

                  const audioList = document.createElement('ul');
                  audioList.className = 'plyr__menu__list';

                  audioTracks.forEach((track, index) => {
                    const audioItem = document.createElement('li');
                    audioItem.className = `plyr__menu__item ${index === hls.audioTrack ? 'plyr__menu__item--selected' : ''}`;

                    const audioButton = document.createElement('button');
                    audioButton.type = 'button';
                    audioButton.className = 'plyr__control';
                    audioButton.dataset.audioIndex = index;
                    audioButton.textContent = track.name || `Track ${index + 1}`;

                    // Change the audio track on click
                    audioButton.onclick = () => {
                      hls.audioTrack = index;
                      updateAudioButtonDisplay(track.name || `Track ${index + 1}`);
                      audioMenu.classList.remove('visible'); // Close the menu after selection

                      // Update selected state
                      audioList.querySelectorAll('.plyr__menu__item').forEach(item => {
                        item.classList.remove('plyr__menu__item--selected');
                      });
                      audioItem.classList.add('plyr__menu__item--selected');
                    };

                    audioItem.appendChild(audioButton);
                    audioList.appendChild(audioItem);
                  });

                  audioMenu.appendChild(audioList);
                  settingsMenu.appendChild(audioMenu);
                }
                // Function to update the audio button display
                function updateAudioButtonDisplay(selectedTrackName) {
                  const audioButton = document.querySelector('.plyr__control[data-plyr="audio"] .plyr__menu__value');
                  if (audioButton) {
                    audioButton.textContent = selectedTrackName;
                  }
                }

                hls.on(Hls.Events.ERROR, (event, data) => {
                  // console.error('HLS error:', data);
                });

                // Clean up Hls instance on unmount
                return () => {
                  hls.destroy();
                };
              } else if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
                // Fallback for Safari
                video.src = videoSrc;
                video.addEventListener('loadedmetadata', () => {
                  video.play().catch((error) => {
                    console.error("Error playing video on Safari:", error);
                  });
                });
              } else {
                console.error('HLS not supported in this browser');
              }
            }



            setIsPlyrLoaded(true);

            // Cleanup on unmount
            return () => {
              if (player) player.destroy();
            };
          }
        })
        .catch(error => console.error(error));
    }
    if (videoSetting?.adLevelVideo) {
      setAdLevel(videoSetting.adLevelVideo);
    }
  }, [videoUrl, getFileDetail, videoSetting, isCaptionsReady]); // Include videoUrl as dependency

  const handlePlay = () => {
    setShowTitle(false);
  }

  // Get the current browser URL
  const browserUrl = window.location.href;
  const url = new URL(browserUrl);

  // Extract the base URL (origin)
  const baseUrl = url.origin;

  const iframeEmbedCode = `<iframe src="${baseUrl}/e/${id}" width="${iframeWidth}" height="${iframeHeight}" allowfullscreen allowtransparency allow="autoplay" scrolling="no" frameborder="0"></iframe>`;
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.setAttribute('data-cfasync', 'false');
  //   script.src = scriptSrc;

  //   // Append the script to the document body
  //   document.body.appendChild(script);

  //   // Cleanup function
  //   return () => {
  //     if (script.parentNode) {
  //       script.parentNode.removeChild(script);
  //     }
  //   };
  // }, [scriptSrc]);

  // jugad

  useEffect(() => {
    setTimeout(function () {
      setIsCaptionsReady(true);
    }, 2000);
  }, []);

  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    const elements = document.getElementsByClassName('isAuthenticated');
    if (elements.length > 0) {
      elements[0].style.marginLeft = 'auto';
      const ulElement = elements[0].querySelector('ul'); // Find the <ul> inside the first matched element
      if (ulElement) {
        const liElements = ulElement.querySelectorAll('li'); // Get all <li> elements
        liElements.forEach((li, index) => {
          if (index !== 0) {
            li.style.display = 'none'; // Hide all <li> except the first one
          }
        });
      }
    }
    if (token) {
      dispatch(getUserDetail()).then((res) => {
        setShowAds(res?.payload?.data?.noAds);
      })
    }
  }, []);

  useEffect(() => {
    if ((showAds === 0 || showAds === false) && isFileOwner === false) {
      const addScript = (src, adLevel, attributes = {}) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.type = "text/javascript";
        script.setAttribute("data-cfasync", "false");
        script.setAttribute("data-ad-level", adLevel); // Add a custom attribute for tracking
        // Add additional attributes to the script tag
        Object.entries(attributes).forEach(([key, value]) => {
          script.setAttribute(key, value);
        });

        document.body.appendChild(script);
        scriptRefs.current.push(script); // Track the script
      };

      // Remove existing scripts (optional)
      // const existingScripts = document.querySelectorAll("script[data-ad-level]");
      // existingScripts.forEach((script) => script.remove());

      // Dynamically add scripts based on ad level
      switch (adLevel) {
        case "MINIMUM":
          addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "MINIMUM");
          addScript("//d3eksfxlf7bv9h.cloudfront.net/?fsked=1141349", "MINIMUM");
          break;
        case "MEDIUM":
          addScript("//pl24693021.profitablecpmrate.com/24/b3/5b/24b35b4bb557a50f76894b8e8bccd7f5.js", "MEDIUM");
          addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "MEDIUM");
          addScript("//d3eksfxlf7bv9h.cloudfront.net/?fsked=1141349", "MINIMUM");
          break;
        case "NORMAL":
          addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "NORMAL");
          addScript("//am.noouplit.com/rkPAvyGxuO83rX6RI/110475", "NORMAL");
          addScript("//pl24693021.profitablecpmrate.com/24/b3/5b/24b35b4bb557a50f76894b8e8bccd7f5.js", "NORMAL");
          addScript("//d3eksfxlf7bv9h.cloudfront.net/?fsked=1141349", "MINIMUM");
          break;
        case "MAXIMUM":
          addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "MAXIMUM");
          addScript("//pl24693021.profitablecpmrate.com/24/b3/5b/24b35b4bb557a50f76894b8e8bccd7f5.js", "MAXIMUM");
          addScript("//am.noouplit.com/rkPAvyGxuO83rX6RI/110475", "MAXIMUM");
          addScript("//d3eksfxlf7bv9h.cloudfront.net/?fsked=1141349", "MINIMUM");
          break;
        default:
          break;
      }
    }
    // Optional Cleanup (if scripts need to be removed on unmount)
    return () => {
      document.querySelectorAll(`script[data-ad-level="${adLevel}"]`).forEach((script) => {
        document.body.removeChild(script);
      });
      // Cleanup all dynamically added scripts
      scriptRefs.current.forEach((script) => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      });
      scriptRefs.current = [];
    };
  }, [isFileOwner, adLevel]);

  // Download functionality

  const [progress, setProgress] = useState(0);

  const handleDownload = (downloadUrl) => {
    const getAlternateUrl = (url) => {
      const lastSlashIndex = url.lastIndexOf('/');
      return url.substring(0, lastSlashIndex + 1) + 'original'; // Replace the last segment with 'original'
    };

    const alternateUrl = getAlternateUrl(downloadUrl); // Generate the alternate URL dynamically

    const downloadFile = (url) => {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (progressEvent?.lengthComputable) {
            const totalLength = progressEvent.total || parseInt(progressEvent.target.getResponseHeader('content-length') || '0', 10);
            if (totalLength !== 0) {
              setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
            }
          }
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', videoName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setProgress(0); // Reset progress after download
      }).catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error('Download failed with 404. Retrying with alternate URL...');
          if (url !== alternateUrl) {
            downloadFile(alternateUrl); // Retry with the alternate URL
          } else {
            toast.error('File not available for download');
            setProgress(0); // Reset progress after error
          }
        } else {
          console.error('Download failed:', error);
          toast.error('An error occurred while downloading the file');
          setProgress(0); // Reset progress after error
        }
      });
    };
    downloadFile(downloadUrl); // Start the download process
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="nofollow, noindex, noimageindex" />
        <script type="text/javascript">
          {`
          (function(s, u, z, p) {
            s.src = u;
            s.setAttribute('data-zone', z);
            (p || document.body || document.documentElement).appendChild(s);
          })(
            document.createElement('script'),
            'https://shebudriftaiter.net/tag.min.js',
            8759910,
            document.body || document.documentElement
          );
        `}
        </script>
      </Helmet>
      <style>
        {`
          .plyr__control--overlaid {
            background: rgb(${colors.overlaidBackground}) !important;
          }
          .plyr--full-ui input[type=range] {
            color: rgb(${colors.rangeColor}) !important;
          }
          .top-video button:hover {
            background: rgb(${colors.buttonHoverBackground}) !important;
          }
          .plyr--video .plyr__control:hover {
            background: rgb(${colors.controlHoverBackground}) !important;
          }
        `}
      </style>

      <section className="video-single-sec  p-100">
        <div className="container">
          <div id="container-43ace20bd01778ef026f1ec9369fbc6f" ref={containerRef}></div>
          <div className="row">
            <div className="col-md-12">
              <div className="heading-head mb-5 text-center">
                {/* <span className="sub-heading mb-1">Welcome videos</span> */}
                <h2 style={{ wordBreak: 'break-all', marginTop: '25px' }}>{videoName}</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="top-video">
                {/* <video
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate}
                  width="100%"
                  height="700"
                  src={videoUrl}
                  controls
                ></video> */}
                {isCaptionsReady && <div className="video-container">
                  {
                    !videoSettingResponse?.settings?.videoAccessSettings?.blockDirectAccess && videoSettingResponse?.settings?.channelLogo && (
                      <div className="channel-icon">
                        <img src={videoSettingResponse?.settings?.channelLogo ? videoSettingResponse?.settings?.channelLogo : ''} alt="Channel Icon" />
                      </div>
                    )
                  }
                  {
                    videoSettingResponse?.settings?.videoAccessSettings?.blockDirectAccess === true ? (
                      <div className="alert alert-danger">
                        Direct access to this video is not allowed.
                      </div>
                    ) : (
                      <video
                        ref={playerRef}
                        onPlay={handlePlay}
                        onTimeUpdate={handleTimeUpdate}
                        controls
                        data-plyr-config='{ "title": "Example Title" }'
                        aria-label='{"title": "Example Title"}'
                        poster={videoThumbnail}
                        crossOrigin="anonymous"
                      >
                        <source src={videoUrl} type={'video/mp4'} />
                        {captions?.map((caption, index) => (
                          <track
                            key={index}
                            src={caption.url}
                            kind="subtitles"
                            srcLang={caption.language}
                            label={caption.label}
                          // default={1}
                          />
                        ))}
                      </video>
                    )
                  }
                  {showTitle && videoSettingResponse?.settings?.videoPlayerSettings?.showNameInPlayer && !videoSettingResponse?.settings?.videoAccessSettings?.blockDirectAccess && (
                    <div className="video-title-overlay">
                      {gettedfileData?.fileName}
                    </div>
                  )}
                </div>}
              </div>
              <div className="mid-video mt-4 mb-4">
                {
                  isFileOwner && (
                    <div className="alert alert-info text-center" role="alert">
                      Since you are the file owner, ads are not displayed to you.
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="video-btn-btm text-center">
                {progress > 0 && (
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: '#e0e0e0',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      marginBottom: '10px',
                    }}
                  >
                    <div
                      style={{
                        width: `${progress}%`,
                        height: '12px',
                        backgroundColor: '#4caf50',
                        transition: 'width 0.3s ease-in-out',
                      }}
                    />
                  </div>
                )}
                {videoSettingResponse?.settings?.videoAccessSettings?.showDownloadButton === true && (
                  <button className="w-100 download-btn btn btn-primary mb-5" style={{ display: 'inherit' }} onClick={() => { handleDownload(downloadUrl) }} disabled={progress > 0 && progress < 100}>
                    {progress > 0 && progress < 100 ? `Downloading... ${progress}%` : 'Download Video'}
                  </button>
                )}
                <p className="subheading">Size: {fileSize} MB</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              {
                videoSettingResponse?.settings?.videoAccessSettings?.disallowUnknownReferrer === true ? (
                  <div className="alert alert-danger">
                    Direct access to embedded is not allowed.
                  </div>
                ) : (

                  <div className="field-copy-video-box">
                    <label className="mb-2 w-100">Embed this video:</label>
                    <textarea
                      className="w-100"
                      name="postContent"
                      value={iframeEmbedCode} // Pass the iframe code to textarea
                      rows={4}
                      cols={40}
                      readOnly
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoPlayer;
