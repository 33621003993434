import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getSubtitleLanguage = async (data) => {
    return await axios.post(`${API_URL}/user/get-languages`, data);
};

export const uploadSubtitle = async (obj) => {
    const token = localStorage.getItem('token') || null
    return await axios.post(`${API_URL}/user/upload-video-subtitle`, obj, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const getVideoSubtitle = async (videoId) => {
    const token = localStorage.getItem('token') || null
    return await axios.get(`${API_URL}/user/get-video-subtitles/${videoId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const deleteSubtitle = async (videoId) => {
    const token = localStorage.getItem('token') || null
    return await axios.delete(`${API_URL}/user/delete-subtitle/${videoId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const getAnalytics = async ({ startDate, endDate, groupBy, showWhat }) => {
    const token = localStorage.getItem('token') || null
    return await axios.get(`${API_URL}/user/analytics?from_date=${startDate}&to_date=${endDate}&group_by=${groupBy}&show=${showWhat}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const generateFtpPassword = async (data) => {
    const url = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token') || null
    if (!url) {
        throw new Error('Not able to find the url');
    }
    const response = await axios.post(`${url}/user/get-ftp-password`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return response
};

export const getApiDocUrl = async () => {
    return await axios.get(`${API_URL}/api-doc`);
};

export const setPinUnpinLanguage = async (data) => {
    return await axios.post(`${API_URL}/user/get-languages`, data);
};