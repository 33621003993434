import React from "react";
import Boxyellow from "../../assets/images/box-yellow.png";
import Bluebox from "../../assets/images/box-blue.png";
import Redbox from "../../assets/images/red-box.png";
import "./CopyRightStyles.css";
import ContactUsFooterTop from "../../Components/ContactUsFooterTop/ContactUsFooterTop";

const CopyrightPolicy = () => {
  return (
    <>
      <section className="login-sec p-100 position-relative">
        <div className="banner-animation-icons">
          <span className="yellow-box">
            <img src={Boxyellow} alt="" />
          </span>
          <span className="box-blue">
            <img src={Bluebox} alt="" />
          </span>
          <span className="red-box">
            <img src={Redbox} alt="" />
          </span>
        </div>

        <div className="copyright-sec">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div className="heading-head mb-5">
                  <span className="sub-heading">Know About</span>
                  <h2>Copyright Policy</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="copyright-details">
                  <p>
                    <strong>Notice:</strong> We respond to notices of copyright
                    infringement in accordance with the Digital Millennium
                    Copyright Act ("DMCA"). If you see anything on our site that
                    you believe infringes your copyright rights, you may notify
                    our designated agent by sending the following information:
                  </p>
                  <ul className="listing-savefile">
                    <li>
                      Identification of the copyrighted work(s) claimed to have
                      been infringed (e.g., a copy of or link to your original
                      work, or a clear description of the material at issue)
                    </li>
                    <li>
                      Identification of the material on our servers and
                      information sufficient to allow SaveFiles to locate and
                      remove the material, (i.e., the URL of the allegedly
                      infringing content on SaveFiles)
                    </li>
                    <li>
                      A statement that you have a good faith belief that use of
                      the material in the manner complained of is not authorized
                      by you as copyright owner, or by your agent, or by law
                      (e.g., the use is not allowed by Section 107 of the U.S.
                      Copyright Act)
                    </li>
                    <li>
                      A statement that the information in your notice is
                      accurate, and under penalty of perjury, that you are the
                      owner (or authorized to act on behalf of the owner) of the
                      exclusive copyright right that is allegedly being
                      infringed.
                    </li>
                    <li>
                      Your physical or electronic signature, or of someone
                      authorized to act on your behalf.
                    </li>
                    <li>
                      Instructions on how we may contact you: including email,
                      address, and phone number.
                    </li>
                    <li>
                      Designated Agent: Notifications of claimed infringement
                      may be sent to email: dmca@savefiles.com.
                    </li>
                  </ul>
                  <p>
                    <strong>Invalid or Fraudulent Notices:</strong> You agree
                    that notices that fail to supply the above information may
                    be invalid under the DMCA. Further, under Section 512(f) of
                    the DMCA, you may be liable for any damages, including costs
                    and attorneys’ fees incurred by SaveFiles or our users, if
                    you knowingly materially misrepresent that material or
                    activity is infringing.
                  </p>
                  <p>
                    <strong>Repeat Infringers:</strong> It is our policy to
                    terminate the account of any user we determine, in our sole
                    discretion, to be a repeat infringer of copyrights.
                  </p>
                  <p>
                    <strong>Repeat Infringers:</strong> It is our policy to
                    terminate the account of any user we determine, in our sole
                    discretion, to be a repeat infringer of copyrights.
                  </p>
                  <p>
                    <strong>Counter Notice:</strong> If you receive a DMCA
                    notice about material posted to your account that you
                    believe is in error, you may file a counter-notice. If we
                    receive a DMCA notice and remove something you posted
                    anonymously, we will have no way of notifying you, so you
                    will have to contact us if you think that may have happened.
                    Keep in mind that we reserve the right to remove any content
                    at any time whether or not it infringes or violates any of
                    our policies. Please note that simply re-posting the
                    material without permission from the copyright holder will
                    result in the permanent deletion of your SaveFiles account.
                  </p>
                  <p>
                    To submit a <strong> counter-notice,</strong> please provide
                    us with the following information that will be sent to the
                    copyright holder or his/her agent
                  </p>
                  <ul className="listing-savefile">
                    <li>
                      A physical or electronic signature (typing your full name
                      is fine).
                    </li>
                    <li>
                      Identification of the material that has been removed or to
                      which access has been disabled and the location at which
                      the material appeared before it was removed or access to
                      it was disabled (the description from the DMCA notice will
                      suffice).
                    </li>
                    <li>
                      A statement under penalty of perjury that you have a
                      good-faith belief that the material was removed or
                      disabled as a result of mistake or misidentification of
                      the material to be removed or disabled and
                    </li>
                    <li>
                      Your name, address, and telephone number, and a statement
                      that you consent to the jurisdiction of the federal
                      district court for the judicial district in which your
                      address is located, or if your address is outside of the
                      United States, for any judicial district in which
                      SaveFiles may be found, and that you will accept service
                      of process from the person who provided the copyright
                      notification or an agent of such person.
                    </li>
                    <li>
                      Your physical or electronic signature, or of someone
                      authorized to act on your behalf.
                    </li>
                    <li>
                      If the copyright holder or agent does not file legal
                      action against you, the removed content may be restored
                      within 10 to 14 business days, in our sole discretion.
                    </li>
                    <li>
                      Trademark or Other Claims: You may use the same notice
                      procedure for any claimed trademark violations or other
                      infringements.
                    </li>
                  </ul>
                  {/* New text added */}
                  <p>
                    SaveFiles respects the intellectual property rights of others. Per the DMCA, we will respond expeditiously to claims of copyright infringement on the Site if submitted to our Copyright Agent as described below. Upon receipt of a notice alleging copyright infringement, We will take whatever action it deems appropriate within its sole discretion, including removal of the allegedly infringing materials and termination of access for repeat infringers of copyright-protected content.
                  </p>
                  <p>
                    If you believe that your intellectual property rights have been violated by us or by a third party who has uploaded materials to our website, please provide the following information to the designated Copyright Agent listed below:
                  </p>
                  <ul className="listing-savefile">
                    <li>
                      A description of the copyrighted work or other intellectual property that you claim has been infringed;
                    </li>
                    <li>
                      A description of where the material that you claim is infringing is located on the Site;
                    </li>
                    <li>
                      An address, telephone number, and email address where we can contact you and, if different, an email address where the alleged infringing party, if not we, can contact you;
                    </li>
                    <li>
                      A statement that you have a good-faith belief that the use is not authorized by the copyright owner or other intellectual property rights owner, by its agent, or by law;
                    </li>
                    <li>
                      A statement by you under penalty of perjury that the information in your notice is accurate and that you are the copyright or intellectual property owner or are authorized to act on the owner's behalf;
                    </li>
                    <li>
                      Your electronic or physical signature.
                    </li>
                  </ul>
                  <p>SaveFiles may request additional information before removing any allegedly infringing material. In the event we remove the allegedly infringing materials, we will immediately notify the person responsible for posting such materials that we removed or disabled access to the materials. We may also provide the responsible person with your email address so that the person may respond to your allegations.</p>

                  <p>Pursuant to 17 U.S.C. 512(c). SaveFiles designated Copyright Agent is:</p>

                  <p>Jonathan Bailey <br />
                    CopyByte <br />
                    3157 Gentilly Blvd Suite # 2254 <br />
                    New Orleans, LA 70122 <br />
                    Phone: 1-504-356-4555  <br />
                    Email: savefiles-dmca@copybyte.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactUsFooterTop />
    </>
  );
};

export default CopyrightPolicy;
