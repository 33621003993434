import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { getApiDoc } from '../../features/common/commonSlice';
import './SwaggerAPI.css'

const SwaggerApiDoc = () => {

    // get the API URL from redux store
    const { apiDocUrl } = useSelector((state) => state.common);

    const dispatch = useDispatch();

    // Dispatch API for grtting the response.
    useEffect(() => {
        dispatch(getApiDoc());
    }, [dispatch])

    return (
        <div>
            <div className="file-upload-section webfile-upload">
                <div className="bottom-list pt-0 p-100 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scroll-file">
                                    {/* Swagger UI to hange the API documentation pass URL as param */}
                                    <SwaggerUI url={`${process.env.REACT_APP_API_URL}/api-doc`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwaggerApiDoc