import { createAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteSubtitle, generateFtpPassword, getAnalytics, getApiDocUrl, getSubtitleLanguage, getVideoSubtitle, setPinUnpinLanguage, uploadSubtitle } from "../../services/commonService";


export const getLanguages = createAsyncThunk(
    "common/get-languages",
    async (data, { rejectWithValue }) => {
        try {
            const response = await getSubtitleLanguage(data)
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const uploadVideoSubtitle = createAsyncThunk(
    'setting/upload-subtitle',
    async (obj, { rejectWithValue }) => {
        try {
            const response = await uploadSubtitle(obj);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getVideoSubtitles = createAsyncThunk(
    "common/get-video-subtitle",
    async (videoId, thunkAPI) => {
        try {
            const response = await getVideoSubtitle(videoId)
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteVideoSubtitle = createAsyncThunk(
    "common/delete-subtitle",
    async (videoId, thunkAPI) => {
        try {
            const response = await deleteSubtitle(videoId)
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const getUserAnalytics = createAsyncThunk(
    "common/get-user-analytics",
    async ({ startDate, endDate, groupBy, showWhat }, { rejectWithValue }) => {
        try {
            const response = await getAnalytics({ startDate, endDate, groupBy, showWhat })
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid Data');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const ftpPassword = createAsyncThunk(
    'auth/ftpPassword',
    async (data, { rejectWithValue }) => {
        try {
            const response = await generateFtpPassword(data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getApiDoc = createAsyncThunk(
    "common/get-api-doc",
    async (thunkAPI, { rejectWithValue }) => {
        try {
            const response = await getApiDocUrl()
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid Url');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const setPinUnpin = createAsyncThunk(
    "common/set-pinunpin",
    async (data, { rejectWithValue }) => {
        try {
            const response = await setPinUnpinLanguage(data)
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const resetState = createAction("RevertAll");

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        language: {},
        isError: false,
        isLoading: false,
        isSuccess: false,
        message: "",
        ftpPasswordStatus: 'idle',
        ftpPasswordData: {},
        apiDocUrl: ''
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLanguages.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLanguages.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.language = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(getLanguages.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(uploadVideoSubtitle.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploadVideoSubtitle.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.uploadSubtitle = action.payload;
            })
            .addCase(uploadVideoSubtitle.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getVideoSubtitles.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getVideoSubtitles.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.videoSubtitle = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(getVideoSubtitles.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteVideoSubtitle.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteVideoSubtitle.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.deleteSubtitle = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(deleteVideoSubtitle.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getUserAnalytics.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserAnalytics.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.analytics = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(getUserAnalytics.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(ftpPassword.pending, (state) => {
                state.ftpPasswordStatus = 'loading';
            })
            .addCase(ftpPassword.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.ftpPasswordStatus = 'succeeded';
                    state.ftpPasswordData = action.payload;
                } else {
                    state.ftpPasswordStatus = 'failed';
                }
            })
            .addCase(ftpPassword.rejected, (state, action) => {
                state.ftpPasswordStatus = 'failed';
            })
            .addCase(getApiDoc.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getApiDoc.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.apiDocUrl = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(getApiDoc.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(setPinUnpin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(setPinUnpin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.language = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(setPinUnpin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    }
})

export default commonSlice.reducer;