import React, { useEffect, useState } from "react";
import $ from "jquery";
import DashboardLogo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { MdMenuOpen } from "react-icons/md";
import { MdOutlineMenu } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { RiShutDownLine } from "react-icons/ri";
import { HiDesktopComputer } from "react-icons/hi";
import UserImg from "../../../assets/images/user-img.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../features/auth/authSlice";
import {
  getUserDetail,
  getNotifications,
} from "../../../features/profile/profileSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DashboardHeader = () => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const data = useSelector(state => state.profile.name);
  const { notificationData } = useSelector(state => state.profile);

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    setName(data);
  }, [data]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("btn-primary");
    };
  }, []);

  //get Notifications
  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const handleLogout = () => {
    document.body.classList.remove("btn-primary");
    sessionStorage.removeItem('tabIndex')
    dispatch(logout());
  };
  useEffect(() => {
    try {
      $(document).ready(function () {
        $(".AddClass").click(function () {
          $(".dashboard-body").addClass("OpenMenu");
        });
        $(".RemoveClass").click(function () {
          $(".dashboard-body").removeClass("OpenMenu");
        });
        $(".overlay").click(function () {
          $(".dashboard-body").removeClass("OpenMenu");
        });
      });
    } catch (error) {
      toast.error(error, {});
    }
  }, []);

  return (
    <>
      <div className="overlay"></div>
      <header className="dashboard-header">
        <div className="container-fluid">
          <div className="dashborad-inner-header d-flex align-items-center flex-wrap">
            <div className="logo-dashboard">
              <Link rel="shtylesheet" to={"/"}>
                <img src={DashboardLogo} alt="" />
              </Link>
            </div>
            <div className="menu-btn-part d-flex flex-wrap align-items-center">
              <button className="rounded-circle AddClass">
                <MdMenuOpen />
              </button>
              <button className="rounded-circle RemoveClass">
                <MdOutlineMenu />
              </button>
            </div>
            <div className="admin-header-part d-flex flex-wrap align-items-center">
              <div className="notification-box">
                <button
                  className="notification-item dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <IoMdNotifications /> <span className="count"></span>
                </button>
                <ul className="dropdown-menu">
                  {/* Notification logic to write here */}

                  {notificationData && notificationData.length > 0 ? (
                    notificationData.map((item, key) => (
                      <li key={key}>
                        <Link
                          className="dropdown-item notification-item d-flex align-items-center"
                          to="">
                          <div className="noti-icons">
                            <HiDesktopComputer />
                          </div>
                          <div className="noti-text">
                            <span className="heading">
                              {item.title}
                            </span>
                            <span className="subheading">
                              {item.description}
                            </span>
                          </div>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>No Notifications!</div>
                  )}
                </ul>
              </div>
              <div className="myAccWrapper">
                <button
                  className="myAcc d-flex align-items-center dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <div className="Userimg">
                    <span className="rounded-circle">
                      <img src={UserImg} alt="" />
                    </span>
                  </div>
                  <div className="UserInfo">
                    <h4>{name}</h4>
                    <p className="mb-0">CEO</p>
                  </div>
                </button>

                <ul className="dropdown-menu">
                  <li onClick={handleLogout}>
                    <Link rel="stylesheet" className="dropdown-item" to="">
                      <span className="icons">
                        <RiShutDownLine />
                      </span>{" "}
                      Logout
                    </Link>
                  </li>
                  <li>
                    <Link
                      rel="stylesheet"
                      className="dropdown-item"
                      to={"account-settings"}>
                      {/* <span className="icons">
                        <RiShutDownLine />
                      </span>{" "} */}
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      rel="stylesheet"
                      className="btn btn-blue dropdown-item"
                      to="">
                      Go to Home
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
