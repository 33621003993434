import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFileUrlV2, getOtherFileDetails } from "../../features/files/fileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFileDetails } from "../../services/fileService";
import { getVideoSettings } from "../../features/videoSetting/videosettingSlice";
import { toast } from "react-toastify";
import NotFound from "../NotFound/NotFound";
import axios from "axios";

const ImageViewer = () => {
    const { videoSetting } = useSelector((state) => state.videosetting)
    const [imageUrl, setImageUrl] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const { gettedfileData, getFileDetail } = useSelector(
        (state) => state.file
    );
    const fileName = getFileDetail?.name;

    const [imageName, setImageName] = useState();
    const [fileSize, setFileSize] = useState();
    const [fileErrorFound, setFileErrorFound] = useState(false);

    useEffect(() => {
        const fetchFileDetails = async () => {
            try {
                // Dispatch to fetch file details initially
                const fileDetails = await getFileDetails(id);
                if (fileDetails.status === 200) {
                    const data = {
                        id: id,
                        fileName: fileDetails.data.data.name
                    }
                    dispatch(getFileUrlV2(data));
                }
            } catch (error) {
                setFileErrorFound(true);
                toast.error(error?.response?.data?.message)
                console.error("Error fetching file details:", error);
            }
        };

        fetchFileDetails();
    }, [id]);

    useEffect(() => {
        dispatch(getVideoSettings());
        dispatch(getOtherFileDetails(id)).then((res) => {
            setImageName(res?.payload?.data?.fileName);
            setFileSize(res?.payload?.data?.fileSize);
        })
    }, [dispatch]);

    // useEffect(() => {
    //     // dispatch(getFileUrl(id));

    //     dispatch(getVideoFileDetails(id));
    //     const data = {
    //         id: id,
    //         fileName: fileName
    //     }
    //     console.log(data,'data')

    //     dispatch(getFileUrlV2(data));

    //     // https://pdrk9001.elb.cisinlive.com/user/getFileUrlV2?filename=71_1730963951818-196974147.png&key=3TfCUueOpzwc8CA
    // }, [id, dispatch]);

    useEffect(() => {
        if (gettedfileData?.url !== "") {
            setImageUrl(gettedfileData?.url);
        }
    }, [gettedfileData]);


    // Download functionality
    const [progress, setProgress] = useState(0);

    const handleDownload = () => {
        axios({
            url: `${process.env.REACT_APP_API_URL}/user/download-file/${id}`, // API endpoint,
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                const totalLength = progressEvent?.lengthComputable
                    ? progressEvent.total
                    : parseInt(progressEvent.target.getResponseHeader('content-length') || '0', 10);
                if (totalLength !== 0) {
                    setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
                }
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', imageName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            setProgress(0); // Reset progress after download
        }).catch((error) => {
            console.error('Download failed:', error);
            setProgress(0); // Reset progress on error
        });
    };


    if (fileErrorFound) {
        return (
            <NotFound />
        )
    }
    return (
        <>
            <section className="image-single-sec p-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-head mb-5 text-center">
                                {/* <span className="sub-heading mb-1">Welcome Images</span> */}
                                <h2>{imageName}</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="top-image">
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt={imageName}
                                        style={{ width: "100%", height: "auto" }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="video-btn-btm text-center mt-3">
                                {progress > 0 && (
                                    <div
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#e0e0e0',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: `${progress}%`,
                                                height: '12px',
                                                backgroundColor: '#4caf50',
                                                transition: 'width 0.3s ease-in-out',
                                            }}
                                        />
                                    </div>
                                )}
                                {videoSetting?.videoAccessSettings?.showDownloadButton === true && (
                                    <button className="w-100 download-btn btn btn-primary mb-5" style={{ display: 'inherit' }} onClick={() => { handleDownload() }} disabled={progress > 0 && progress < 100}>
                                        {progress > 0 && progress < 100 ? `Downloading... ${progress}%` : 'Download'}
                                    </button>
                                )}
                                {/* <p className="subheading">Size: {fileSize} MB</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ImageViewer;
